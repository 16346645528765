import http from '../instances/http';

const AuthApi = {
  async getNonce(publicAddress) {
    const res = await http.get('/auth/nonce', { params: { publicAddress } });
    return res.data;
  },
  async authUser(publicAddress, signature) {
    const res = await http.get('/auth/verify-signature', {
      params: {
        publicAddress,
        signature
      }
    });
    return res.data;
  },
  async getData(publicAddress, referralAddress, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const res = await http.get(
      '/main/data',
      {
        params: {
          publicAddress,
          referralAddress,
        },
        headers
      });
    return res.data;
  },
  async getImage(publicAddress, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const res = await http.get('/main/data/image', {
      params: { publicAddress },
      headers
    });
    return res.data;
  },
};

export default AuthApi;
