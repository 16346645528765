import { Navigate, Route, Routes } from 'react-router-dom';

// store
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectCurrentModal } from '../../store/client';

// components
import ConnectWalletModal from '../ConnectWalletModal/ConnectWalletModal';
import ReferralModal from '../ReferralModal/ReferralModal';
import SuccessModal from '../SuccessModal/SuccessModal';
import EarnPage from './EarnPage/EarnPage';

import CopyImg from '../../assets/copy_placeholder.svg';
import LogoImg from '../../assets/logo_placeholder.svg';

// constants
import Modals from '../../shared/constants/modals';

// styles
import styles from './AppView.module.css';
import StakeProcessSuccessModal from '../StakeProcessSuccessModal/StakeProcessSuccessModal';
import {useState} from 'react';
import Button from "../common/Button/Button";

export default function AppView() {
  const dispatch = useDispatch();
  const currentModal = useSelector(selectCurrentModal);
  const email = 'tokenfyllc@gmail.com';
  // const [copied, setCopied] = useState(false);

  function handleCloseModal() {
    dispatch(closeModal());
  }

  // function copy() {
  //   if (!copied) {
  //     setCopied(true);
  //     window.navigator.clipboard.writeText(email);
  //
  //     setTimeout(() => setCopied(false), 2000);
  //   }
  // }

  return (
    <div>
      <div className={styles.app_view_wrapper}>
        <div className={styles.hero_main_wrapper}>
          <header className={styles.header}>
            <div className={styles.header_content}>
              <img className={styles.header_img} src={LogoImg} alt="logo"/>
            </div>
          </header>
          <main className={styles.main}>
            <h3 className={styles.main_h3}>Contact:</h3>
            <p className={styles.main_info}>
              <a className={styles.main_info_a} href={`mailto:${email}`}>{email}</a>
            </p>
            {/*<p className={`${styles.main_copy_text} ${copied ? styles.copied : ''}`}>*/}
            {/*  Copied to clipboard*/}
            {/*</p>*/}
            <a className={styles.main_info_link} href="#stack_block">
              <Button>STAKING</Button>
            </a>
          </main>
        </div>
        <EarnPage/>
      </div>
      <ConnectWalletModal
        isOpen={currentModal === Modals.Connect}
        handleClose={handleCloseModal}
      />
      <SuccessModal/>
      <StakeProcessSuccessModal/>
      <ReferralModal/>
    </div>
  );
}
