import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// instances
import { metamaskProvider } from './instances/ethers';
import { connector } from './instances/wallet-connect';

// store
import { useDispatch, useSelector } from 'react-redux';
import {
  checkClaim,
  handleAddressChange,
  handleChainChange,
  handleDisconnect,
  loadConnectionData, loadUserData,
  requestContractData,
  requestLPAPRData,
  selectAddress,
  selectConnectionType,
  selectError,
  selectIsLoading, selectUserData,
  setReferralAddress
} from './store/client';

// components
import AppView from './components/AppView/AppView';
import Loader from './components/Loader/Loader';
import ErrorPopup from './components/ErrorPopup/ErrorPopup';

// constants
import ConnectionType from './shared/constants/connection-type';

function App() {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const connectionType = useSelector(selectConnectionType);
  const address = useSelector(selectAddress);
  const userData = useSelector(selectUserData);
  const error = useSelector(selectError);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // if (address && !userData) {
    //   dispatch(loadUserData());
    // }
  }, [address])

  useEffect(() => {
    dispatch(loadConnectionData());
    // dispatch(requestLPAPRData());

    // const timerId = setInterval(() => {
    //   dispatch(requestLPAPRData());
    // }, 300000);

    // return () => {
    //   clearInterval(timerId);
    // };
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkClaim());
  }, [dispatch, address]);

  useEffect(() => {
    dispatch(requestContractData());
    dispatch(setReferralAddress(searchParams.get('referralAddress')));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (metamaskProvider) {
      metamaskProvider.on(
        'accountsChanged',
        ([address]) => dispatch(handleAddressChange({ address, type: ConnectionType.Metamask })),
      );
      metamaskProvider.on(
        'chainChanged',
        (chainId) => dispatch(handleChainChange({ chainId, type: ConnectionType.Metamask })),
      );
      metamaskProvider.on('disconnect', () => dispatch(handleDisconnect(ConnectionType.Metamask)));
    }

    if (connector) {
      connector.on('connect', (_, payload) => {
        const { accounts } = payload.params[0];

        dispatch(handleAddressChange({ address: accounts[0], type: ConnectionType.WalletConnect }));
        dispatch(handleChainChange({ isMainNet: true, type: ConnectionType.WalletConnect }));
      });
      connector.on('disconnect', () => dispatch(handleDisconnect(ConnectionType.WalletConnect)));
    }

    return () => {
      if (metamaskProvider) {
        metamaskProvider.removeAllListeners('accountsChanged');
        metamaskProvider.removeAllListeners('chainChanged');
        metamaskProvider.removeAllListeners('disconnect');
      }

      if (connector) {
        connector.off('connect');
        connector.off('disconnect');
      }
    };
  }, [dispatch, connectionType, loading]);

  return (
    <>
      <AppView />
      <ErrorPopup isOpen={!!error} error={error} />
      {loading ? <Loader /> : null}
    </>
  );
}

export default App;
