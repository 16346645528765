import { ethers } from 'ethers';

// instances
import { signer, web3MainNet } from './ethers';

// abi
import feClaimAbi from '../assets/abis/fe-claim-abi.json';
import stakingAbi from '../assets/abis/staking-contract.json';
import lpAbi from '../assets/abis/lp-contract.json';

const feClaimContract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, feClaimAbi, web3MainNet);
const feClaimContractWithSigner = feClaimContract.connect(signer);
const feClaimContractIFace = new ethers.utils.Interface(feClaimAbi);

const stakingContract = new ethers.Contract(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS, stakingAbi, web3MainNet);
const stakingContractWithSigner = stakingContract.connect(signer);
const stakingContractIFace = new ethers.utils.Interface(stakingAbi);

const lpContract = new ethers.Contract(process.env.REACT_APP_LP_STAKING_CONTRACT_ADDRESS, lpAbi, web3MainNet);
const lpContractWithSigner = lpContract.connect(signer);
const lpContractIFace = new ethers.utils.Interface(lpAbi);

const lpTokenContract = new ethers.Contract(process.env.REACT_APP_LP_TOKEN_CONTRACT_ADDRESS, feClaimAbi, web3MainNet);
const lpTokenContractWithSigner = lpTokenContract.connect(signer);
const lpTokenContractIFace = new ethers.utils.Interface(feClaimAbi);

export {
  feClaimContract,
  feClaimContractWithSigner,
  feClaimContractIFace,
  stakingContract,
  stakingContractWithSigner,
  stakingContractIFace,
  lpContract,
  lpContractWithSigner,
  lpContractIFace,
  lpTokenContract,
  lpTokenContractWithSigner,
  lpTokenContractIFace,
};
