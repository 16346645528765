async function contractErrorParser(transactionCall) {
  try {
    return await transactionCall();
  } catch (e) {
    const msg = e.error?.data?.originalError?.message || e.reason;
    if (msg) {
      throw new Error(msg);
    } else {
      throw e;
    }
  }
}

export default contractErrorParser;
