import http from '../instances/http';

const PricesApi = {
  async getPricesData() {
    const data = (await http.get('/main/rate')).data;

    return {
      ethPrice: +data.ethPrice.replaceAll(',', ''),
      tknfyPrice: +data.tknfyPrice.replaceAll(',', ''),
      tknfyPoolAmount: +data.tknfyPoolAmount.replaceAll(',', ''),
      ethPoolAmount: +data.ethPoolAmount.replaceAll(',', ''),
    }
  },
};

export default PricesApi;
