import { BounceLoader } from 'react-spinners';

// styles
import styles from './Loader.module.css';

export default function Loader() {
  return (
    <div className={styles.loader_overlay}>
      <BounceLoader color="#41ABE1"/>
    </div>
  );
}
