import Modal from 'react-modal';
import classNames from 'classnames';

// components
import styles from './StyledModal.module.css';

export default function StyledModal(
  {
    children,
    className,
    contentClassName,
    title,
    handleClose,
    ...props
  }) {
  return (
    <Modal
      {...props}
      appElement={document.getElementById('root')}
      className={classNames(styles.styled_modal, className)}
      overlayClassName={styles.modal_overlay}
      onRequestClose={handleClose}>

      <div className={classNames(styles.modal_content, contentClassName)}>
        <div className={styles.modal_header}>
          <h2 className={styles.modal_title}>{title}</h2>
          <button
            className={styles.modal_close_button}
            onClick={handleClose}
          ><span>&#x2716;</span></button>
        </div>
        {children}
      </div>
    </Modal>
  );
}
