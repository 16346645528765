import { ethers } from 'ethers';

// instances
import {
  feClaimContract,
  feClaimContractWithSigner,
  feClaimContractIFace,
  stakingContract,
  stakingContractWithSigner,
  stakingContractIFace,
  lpContract,
  lpContractWithSigner,
  lpContractIFace, lpTokenContract, lpTokenContractWithSigner, lpTokenContractIFace,
} from '../instances/contract';
import { connector } from '../instances/wallet-connect';
import { web3 } from '../instances/ethers';

// store
import ConnectionType from '../shared/constants/connection-type';
import ContractType from '../shared/constants/contract-type';

const ContractApi = {
  getContractsByType(type) {
    switch (type) {
      case ContractType.Claim:
        return {
          contract: feClaimContract,
          contractWithSigner: feClaimContractWithSigner,
          iFace: feClaimContractIFace,
        };
      case ContractType.Staking:
        return {
          contract: stakingContract,
          contractWithSigner: stakingContractWithSigner,
          iFace: stakingContractIFace,
        };
      case ContractType.LP:
        return {
          contract: lpContract,
          contractWithSigner: lpContractWithSigner,
          iFace: lpContractIFace,
        };
      case ContractType.LPToken:
        return {
          contract: lpTokenContract,
          contractWithSigner: lpTokenContractWithSigner,
          iFace: lpTokenContractIFace,
        };
    }
  },
  async claimLive() {
    return await feClaimContract.claimLive();
  },
  async getStakingRewards() {
    const res = await stakingContract.stakingRewards();
    return res.toString();
  },
  async getTotalTokenfy() {
    const res = await stakingContract.totalTokenfy();
    return res.toString();
  },
  async getTotalSupply() {
    const res = await stakingContract.totalSupply();
    return res.toString();
  },
  async getLPTokenTotalSupply() {
    const res = await lpTokenContract.totalSupply();
    return res.toString();
  },
  async getLPUserInfo(address) {
    const { amount, rewardDebt } = await lpContract.userInfo(address);
    return {
      amount: amount.toString(),
      rewardDebt: rewardDebt.toString(),
    };
  },
  async getLPStakingRewards(address) {
    const res = await lpContract.stakingRewards(address);
    return res.toString();
  },
  async getLPRewardPerBlock() {
    const res = await lpContract.rewardPerBlock();
    return res.toString();
  },
  async getBalance(address, type) {
    const { contract } = ContractApi.getContractsByType(type);
    const res = await contract.balanceOf(address);
    return res.toString();
  },
  async getApprovedAmount(address, spender, type) {
    const { contract } = ContractApi.getContractsByType(type);
    const res = await contract.allowance(address, spender);
    return res.toString();
  },
  async claimed(address) {
    return await feClaimContract.claimed(address);
  },
  async requestWithSigner(
    connectionType,
    buyerAddress,
    functionName,
    contractType = ContractType.Claim,
    params = [],
    price = 0,
    approver = null,
    approvableCurrency = null,
    waitReceipt = false,
  ) {
    const { contract, contractWithSigner, iFace } = ContractApi.getContractsByType(contractType);

    if (connectionType === ConnectionType.Metamask) {
      return this.requestWithSignerMetamask(
        contractWithSigner,
        buyerAddress,
        functionName,
        params,
        price,
        approver,
        approvableCurrency,
        waitReceipt,
      );
    } else if (connectionType === ConnectionType.WalletConnect) {
      return this.requestWithSignerWalletConnect(
        contract,
        iFace,
        buyerAddress,
        functionName,
        params,
        price,
        approver,
        approvableCurrency,
        waitReceipt,
      );
    }
  },
  async requestWithSignerMetamask(
    contractWithSigner,
    buyerAddress,
    functionName,
    params,
    price,
    approver,
    approvableCurrency,
    waitReceipt,
  ) {
    const override = {};

    if (price) {
      const priceStr = price.toString();
      const priceBN = ethers.BigNumber.from(priceStr);

      if (approver) {
        const balance = await ContractApi.getBalance(buyerAddress, approver);
        if (priceBN.gt(ethers.BigNumber.from(balance))) {
          throw new Error(`Not enough ${approvableCurrency || 'Token'}.`);
        }

        const approvedAmount = await ContractApi.getApprovedAmount(buyerAddress, contractWithSigner.address, approver);
        if (priceBN.gt(ethers.BigNumber.from(approvedAmount))) {
          const { contractWithSigner: approverContract } = ContractApi.getContractsByType(approver);
          const approveTx = await approverContract.approve(contractWithSigner.address, balance);
          await approveTx.wait();
        }
      } else {
        override.value = priceStr;

        const estimatedGas = await contractWithSigner.estimateGas[functionName](...params, override);
        override.gasLimit = Math.round(estimatedGas * 2);
      }
    }

    const tx = await contractWithSigner[functionName](...params, override);

    if (waitReceipt) {
      await tx.wait();
    }

    return tx.hash;
  },
  async requestWithSignerWalletConnect(
    contract,
    iFace,
    buyerAddress,
    functionName,
    params,
    price,
    approver,
    approvableCurrency,
    waitReceipt,
  ) {
    const tx = {
      from: buyerAddress,
      to: contract.address,
      data: iFace.encodeFunctionData(functionName, params),
    };

    if (price) {
      tx.value = price.toString();
      const priceBN = ethers.BigNumber.from(tx.value);

      if (approver) {
        const balance = await ContractApi.getBalance(buyerAddress, approver);
        if (priceBN.gt(ethers.BigNumber.from(balance))) {
          throw new Error(`Not enough ${approvableCurrency || 'Token'}.`);
        }

        const approvedAmount = await ContractApi.getApprovedAmount(buyerAddress, contract.address, approver);
        if (priceBN.gt(ethers.BigNumber.from(approvedAmount))) {
          const { iFace: approverIFace } = ContractApi.getContractsByType(approver);
          const approveTx = {
            from: buyerAddress,
            to: contract.address,
            data: approverIFace.encodeFunctionData('approve', [contract.address, balance]),
          };

          const approveHash = await connector.sendTransaction(approveTx);
          await ContractApi.waitWalletConnectReceipt(approveHash);
        }
      }
    }

    const hash = await connector.sendTransaction(tx);

    return waitReceipt ? await ContractApi.waitWalletConnectReceipt(hash) : hash;
  },
  async waitWalletConnectReceipt(hash) {
    let timerId;

    return new Promise((res, rej) => {
      timerId = setInterval(async () => {
        const receipt = await web3.getTransactionReceipt(hash);

        if (receipt) {
          clearInterval(timerId);
          if (receipt.status) {
            res(hash);
          } else {
            rej(new Error(`The contract execution was not successful, check your transaction ${hash}!`));
          }
        }
      }, 2000);
    });
  },
  async claim(connectionType, claimerAddress, amount, r, s, referral) {
    return await this.requestWithSigner(
      connectionType,
      claimerAddress,
      'claim',
      ContractType.Claim,
      [amount, r, s, referral],
    )
  },
  async stake(connectionType, claimerAddress, price) {
    const parsedPrice = ethers.utils.parseEther(price);
    return await this.requestWithSigner(
      connectionType,
      claimerAddress,
      'stake',
      ContractType.Staking,
      [parsedPrice.toString()],
      parsedPrice,
      ContractType.Claim,
      '$TKNFY',
      true,
    )
  },
  async unstake(connectionType, claimerAddress, price) {
    const parsedPrice = ethers.utils.parseEther(price);
    return await this.requestWithSigner(
      connectionType,
      claimerAddress,
      'unstake',
      ContractType.Staking,
      [parsedPrice.toString()],
      0,
      null,
      null,
      true,
    )
  },
  async lpStake(connectionType, claimerAddress, price) {
    const parsedPrice = ethers.utils.parseEther(price);
    return await this.requestWithSigner(
      connectionType,
      claimerAddress,
      'stake',
      ContractType.LP,
      [parsedPrice.toString()],
      parsedPrice,
      ContractType.LPToken,
      '$TKNFY-ETH LP tokens',
      true,
    )
  },
  async lpUnstake(connectionType, claimerAddress, price) {
    const parsedPrice = ethers.utils.parseEther(price);
    return await this.requestWithSigner(
      connectionType,
      claimerAddress,
      'unstake',
      ContractType.LP,
      [parsedPrice.toString()],
      0,
      null,
      null,
      true,
    )
  },
  async lpClaim(connectionType, claimerAddress) {
    return await this.requestWithSigner(
      connectionType,
      claimerAddress,
      'claim',
      ContractType.LP,
      [],
      0,
      null,
      null,
      true,
    )
  },
};

export default ContractApi;
