import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeModal,
  selectAddress,
  selectCurrentModal,
} from '../../store/client';
import { Share } from 'react-twitter-widgets';

// components
import StyledModal from '../common/StyledModal/StyledModal';

// assets
import PRESENT from '../../assets/Entrepreneurial Community.png';
import COPY from '../../assets/copy.svg';

// constants
import Modals from '../../shared/constants/modals';

// styles
import styles from './ReferralModal.module.css';

export default function ReferralModal() {
  const dispatch = useDispatch();
  const currentModal = useSelector(selectCurrentModal);
  const address = useSelector(selectAddress);

  const textRef = useRef(null);
  const [copied, setCopied] = useState(false);

  function handleCloseModal() {
    dispatch(closeModal());
  }

  async function copy() {
    await navigator.clipboard.writeText(textRef.current.value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <StyledModal
      title="Your referral link"
      className={styles.modal}
      isOpen={currentModal === Modals.Referral}
      handleClose={handleCloseModal}
    >
      <div className={styles.content}>
        <img src={PRESENT} alt="present"/>
        <div className={styles.address_block}>
          <div>
            <textarea
              ref={textRef}
              rows={3}
              value={`${document.location.protocol}//${document.location.host}/?referralAddress=${address}`}
              disabled
            />
            {
              copied
                ? <p>copied!</p>
                : null
            }
          </div>

          <button onClick={copy}>
            <img src={COPY} alt="copy" />
          </button>
        </div>
        <Share
          url=" "
          options={{
            size: 'large',
            text: `Claim your $TKNFY at ${document.location.protocol}//${document.location.host}/?referralAddress=${address} @tokenfy_`,
          }}
        />
      </div>
    </StyledModal>
  );
}
