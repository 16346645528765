import { Share } from 'react-twitter-widgets';

// store
import { useDispatch, useSelector } from 'react-redux';
import {
  closeModal,
  selectAddress,
  selectAPR,
  selectCurrentModal,
  selectCurStakeProcess,
  selectStakingAmount
} from '../../store/client';

// assets
import MEDAL from '../../assets/Easy User Interface.png';

// components
import StyledModal from '../common/StyledModal/StyledModal';

// styles
import styles from './StakeProcessSuccessModal.module.css';

// constants
import Modals from '../../shared/constants/modals';
import StakingProcess from '../../shared/constants/staking-process';

export default function StakeProcessSuccessModal() {
  const dispatch = useDispatch();
  const currentModal = useSelector(selectCurrentModal);
  const curStakeProcess = useSelector(selectCurStakeProcess);
  const stakingAmount = useSelector(selectStakingAmount);
  const address = useSelector(selectAddress);
  const apr = useSelector(selectAPR);

  function close() {
    dispatch(closeModal());
  }

  function switchTitle() {
    switch (curStakeProcess) {
      case StakingProcess.EarnStaking:
        return (<p>You have successfully staked {stakingAmount} <span>$TKNFY</span></p>);
      case StakingProcess.EarnUnstaking:
        return (<p>You have successfully unstaked <span>$TKNFY</span> and received your staking
          rewards!</p>);
      case StakingProcess.LPStaking:
        return (<p>You have successfully staked $TKNFY-ETH LP tokens!</p>);
      case StakingProcess.LPUnstaking:
        return (<p>You have successfully unstaked $TKNFY-ETH LP tokens and received $TKNFY rewards!</p>);
      case StakingProcess.LPMint:
        return (<p>You have successfully claimed your $TKNFY rewards!</p>);
      default:
        return null;
    }
  }

  function switchTwitText() {
    switch (curStakeProcess) {
      case StakingProcess.EarnStaking:
        return `Just successfully staked my $TKNFY! Current APR is ${apr}. Claim your free airdrop here: @tokenfy_ ${document.location.protocol}//${document.location.host}/?referralAddress=${address} #tokenfy`;
      case StakingProcess.EarnUnstaking:
        return `Just successfully received my $TKNFY staking rewards! Current APR is ${apr}. Claim your free airdrop here: @tokenfy_ ${document.location.protocol}//${document.location.host}/?referralAddress=${address} #tokenfy`;
      default:
        return null;
    }
  }

  return (
    <StyledModal
      title="Thank you!"
      className={styles.modal}
      isOpen={currentModal === Modals.StakeProcess}
      handleClose={close}
    >
      <div className={styles.content}>
        <img className={styles.img} src={MEDAL} alt="medal" />
        <p>{switchTitle()}</p>
        {
          switchTwitText()
            ? <div>
              <Share
                url=" "
                options={{
                  size: 'large',
                  text: switchTwitText(),
                }}
              />
            </div>
            : null
        }
      </div>
    </StyledModal>
  );
}
