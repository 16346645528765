import { useDispatch } from 'react-redux';
import { connectLedger, connectMetamask } from '../../store/client';

// components
import StyledModal from '../common/StyledModal/StyledModal';
import Button from '../common/Button/Button';

// assets
import METAMASK from '../../assets/metamask.svg';
import WALLET_CONNECT from '../../assets/walletconnect.svg';

// styles
import styles from './ConnectWalletModal.module.css'

export default function ConnectWalletModal({
  isOpen,
  handleClose,
}) {
  const dispatch = useDispatch();

  function handleMetamask() {
    dispatch(connectMetamask());
  }

  function handleWalletConnect() {
    dispatch(connectLedger());
  }

  return (
    <StyledModal
      title="Connect wallet"
      className={styles.wallet_modal}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className={styles.container}>
        <Button handleClick={handleMetamask}>
          <img src={METAMASK} alt="metamask" />
          <span>Metamask</span>
        </Button>
        <Button handleClick={handleWalletConnect}>
          <img src={WALLET_CONNECT} alt="Wallet connect" />
          <span>Wallet connect</span>
        </Button>
      </div>
    </StyledModal>
  );
}
