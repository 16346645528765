function sessionCheck(isMainNet, address, provider) {
  if (!provider) {
    return 'Please install metamask to use this app.';
  }

  if (!isMainNet) {
    return (
      `You are using the wrong network, please switch to ${process.env.REACT_APP_MAIN_NET_NAME} and reload the page.`
    );
  }

  if (!address) {
    return 'Please connect your wallet to use this app.';
  }

  return null;
}

export default sessionCheck;
