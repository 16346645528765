import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// store
import {
  addStakedToken,
  openModal,
  requestStakingBalance,
  requestStakingData,
  selectAddress,
  selectAPR,
  selectStakedTokenfyBalance,
  selectStakingBalance,
  selectStakingRate,
  selectTokenfyBalance,
  stake,
  unstake,
} from '../../../store/client';

// assets
import STAKING from '../../../assets/Affordable.png';

// components
import Button from '../../common/Button/Button';

// constants
import Modals from '../../../shared/constants/modals';

// styles
import style from './EarnPage.module.css';

const StakingState = {
  stake: 'stake',
  unstake: 'unstake',
};

export default function EarnPage() {
  const dispatch = useDispatch();

  const address = useSelector(selectAddress);

  const APR = useSelector(selectAPR);
  const stakingRate = useSelector(selectStakingRate);
  const tokenfyBalance = useSelector(selectTokenfyBalance);
  const sTokenfyBalance = useSelector(selectStakedTokenfyBalance);
  const stakingBalance = useSelector(selectStakingBalance);

  const [stakingState, setStakingState] = useState(StakingState.stake);
  const [stakeAmount, setStakeAmount] = useState('');

  const changeStakingState = (state) => () => {
    setStakingState(state);
  };

  function openConnectModal() {
    dispatch(openModal(Modals.Connect))
  }

  function handleStakeAmountChange(ev) {
    const value = ev.target.value;
    if (value.match(/^\d*\.?\d*$/)) {
      setStakeAmount(value);
    }
  }

  function setMaxAmount() {
    if (stakingState === StakingState.stake) {
      setStakeAmount(tokenfyBalance);
    } else {
      setStakeAmount(sTokenfyBalance);
    }
  }

  function stakeReq() {
    if (address) {
      dispatch(stake(stakeAmount));
    } else {
      dispatch(openModal(Modals.Connect));
    }
  }

  function unstakeReq() {
    if (address) {
      dispatch(unstake(stakeAmount));
    } else {
      dispatch(openModal(Modals.Connect));
    }
  }

  function addNewToken() {
    if (address) {
      dispatch(addStakedToken());
    } else {
      dispatch(openModal(Modals.Connect));
    }
  }

  function getFormattedBalance(val) {
    return +val.toFixed(6);
  }

  useEffect(() => {
    dispatch(requestStakingData());

    const timerId = setInterval(() => dispatch(requestStakingData()), 60000);

    return () => {
      clearInterval(timerId);
    };
  }, [dispatch]);

  useEffect(() => {
    if (address) {
      dispatch(requestStakingBalance());

      const timerId = setInterval(() => dispatch(requestStakingBalance()), 60000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [dispatch, address]);

  return (
    <div className={style.earn_page} id="stack_block">
      <div className={style.row}>
        <img src={STAKING} alt="staking" />
        <div className={style.staking_block}>
          <h2>Earn by staking</h2>
          {APR
            ? (<div>
              <p>Staking APR</p>
              <p>{APR}%</p>
            </div>)
            : null}
          <p>Stake <span>TKNFY</span> and receive <span>sTKNFY</span> in return.</p>

          {address
            ? <Button handleClick={addNewToken}>ADD sTKNFY TO WALLET</Button>
            : <Button onClick={openConnectModal}>Connect wallet</Button>}

          <div className={style.staking}>
            <div>
              <button
                className={stakingState === StakingState.stake ? style.active_stake_btn : null}
                id="stake"
                onClick={changeStakingState(StakingState.stake)}
                disabled={stakingState === StakingState.stake}
              >
                Stake
              </button>
              <button
                className={stakingState === StakingState.unstake ? style.active_stake_btn : null}
                id="unstake"
                onClick={changeStakingState(StakingState.unstake)}
                disabled={stakingState === StakingState.unstake}
              >
                Unstake
              </button>
            </div>
            <div className={style.staking_row}>
              <p>{stakingState === StakingState.stake ? 'TKNFY balance' : 'sTKNFY balance'}</p>
              <div>
                <p>{getFormattedBalance(stakingState === StakingState.stake ? +tokenfyBalance : +sTokenfyBalance)}</p>
              </div>
            </div>
            {stakingState === StakingState.unstake && stakingRate
              ? <div className={style.staking_row}>
                <span />
                <p>
                  {getFormattedBalance(+sTokenfyBalance)} sTKNFY
                  = {getFormattedBalance(+stakingBalance)} TKNFY
                </p>
              </div>
              : null}
            <div className={style.staking_row}>
              <p>Staked balance</p>
              <div>
                <p>{getFormattedBalance(+stakingBalance)}</p>
              </div>
            </div>
            <label>
              <input value={stakeAmount} onChange={handleStakeAmountChange} />
              <button onClick={setMaxAmount}>MAX</button>
            </label>
            {stakingState === StakingState.unstake
              ? <div className={style.staking_row}>
                <span />
                <p>
                  {getFormattedBalance(+stakeAmount || 0)} sTKNFY
                  {' = '}
                  {getFormattedBalance((stakeAmount || 0) * stakingRate)} TKNFY
                </p>
              </div>
              : null}
            {
              stakingState === StakingState.stake
                ? <Button disabled={!address} onClick={stakeReq}>STAKE</Button>
                : <Button disabled={!address} onClick={unstakeReq}>UNSTAKE</Button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
