import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import QRCodeModal from '@walletconnect/qrcode-modal';

// instances
import { connector } from '../instances/wallet-connect';
import { metamaskProvider, signer, web3 } from '../instances/ethers';
import { lpContract } from '../instances/contract';

// api
import AuthApi from '../api/auth.api';
import ContractApi from '../api/contract.api';
import PricesApi from '../api/prices.api';

// helpers
import contractErrorParser from '../shared/helpers/contract-error-parser';
import sessionCheck from '../shared/helpers/session-check';

// constants
import Modals from '../shared/constants/modals';
import ConnectionType from '../shared/constants/connection-type';
import ContractType from '../shared/constants/contract-type';
import StakingProcess from '../shared/constants/staking-process';

const initialState = {
  currentModal: null,
  curStakeProcess: null,
  stakingAmount: null,

  session: {
    type: null,
    address: null,
    isMainNet: null,
  },

  staking: {
    rewards: null,
    totalTokenfy: null,
    totalSupply: null,
    APR: null,
    rate: null,
  },

  userBalance: {
    tokenfy: '0',
    sTokenfy: '0',
    lpToken: '0',
  },
  lpUserInfo: {
    amount: '0',
    rewardDebt: '0',
  },
  lpStakingRewards: '0',
  lpRewardPerBlock: null,
  lpAPR: null,

  referralAddress: null,
  claimImage: null,
  claimed: null,
  claimLive: null,
  eligible: null,

  forceBlock: false,
  isLoading: null,
  error: null,
};

// async thunks
async function authWithToken(type, address) {
  let signature = null,
    nonce = null;
  try {
    nonce = await AuthApi.getNonce(address);
  } catch (e) {
    throw new Error(e);
  }

  try {
    const message = `I am signing my one-time nonce: ${nonce}`;

    if (type === ConnectionType.Metamask) {
      signature = await signer.signMessage(message);
    } else {
      signature = await connector.signPersonalMessage([
        message,
        ethers.utils.getAddress(address),
      ]);
    }
  } catch (e) {
    throw new Error('User declined signature');
  }

  try {
    const { token } = await AuthApi.authUser(address, signature);
    return {
      token,
      signature
    };
  } catch (e) {
    throw new Error('We couldn\'t validate your signature. For the optimal user experience, we suggest using a desktop or laptop with Google Chrome and a Metamask wallet.');
  }
}

export const connectMetamask = createAsyncThunk(
  'client/connectMetamask',
  async () => {
    if (!metamaskProvider) {
      throw new Error('Please install metamask to use this app.');
    }

    const [address] = await metamaskProvider.request({ method: 'eth_requestAccounts' });
    return ethers.utils.getAddress(address);
  },
);

export const connectLedger = createAsyncThunk(
  'client/connectLedger',
  async () => {
    if (connector.connected) {
      await connector.killSession();
    }

    return await new Promise((res, rej) => {
      if (connector.pending) {
        QRCodeModal.open(connector.uri, () => {
          rej(new Error('modal closed'));
        });
      }

      connector.connect()
        .then(() => res(connector.accounts[0]))
        .catch((err) => rej(err));
    });
  },
);

export const requestContractData = createAsyncThunk(
  'client/requestContractData',
  async () => {
    return {
      claimLive: await ContractApi.claimLive(),
    };
  },
);

export const requestStakingData = createAsyncThunk(
  'client/requestStakingData',
  async () => {
    return {
      stakingRewards: await ContractApi.getStakingRewards(),
      totalTokenfy: await ContractApi.getTotalTokenfy(),
      totalSupply: await ContractApi.getTotalSupply(),
    };
  },
);

export const requestLPAPRData = createAsyncThunk(
  'client/requestLPAPRData',
  async () => {
    const data = await PricesApi.getPricesData();

    return {
      lpTokenBalance: await ContractApi.getBalance(lpContract.address, ContractType.LPToken),
      lpTokenTotalSupply: await ContractApi.getLPTokenTotalSupply(),
      lpRewardPerBlock: await ContractApi.getLPRewardPerBlock(),
      ethPrice: data.ethPrice,
      tknfyPrice: data.tknfyPrice,
      tknfyPoolAmount: data.tknfyPoolAmount,
      ethPoolAmount: data.ethPoolAmount,
    };
  },
);

export const requestStakingBalance = createAsyncThunk(
  'client/requestStakingBalance',
  async (_, slice) => {
    const { address } = slice.getState().session;

    return {
      tokenfy: address ? await ContractApi.getBalance(address, ContractType.Claim) : '0',
      sTokenfy: address ? await ContractApi.getBalance(address, ContractType.Staking) : '0',
      lpToken: address ? await ContractApi.getBalance(address, ContractType.LPToken) : '0',
      lpStakingRewards: address ? await ContractApi.getLPStakingRewards(address) : '0',
      lpUserInfo: address ? await ContractApi.getLPUserInfo(address) : null,
    };
  },
);

export const checkClaim = createAsyncThunk(
  'client/checkClaim',
  async (_, slice) => {
    const state = slice.getState();
    const { address } = state.session;

    return await ContractApi.claimed(address);
  },
);

export const loadUserData = createAsyncThunk(
  'client/loadUserData',
  async (_, slice) => {
    const state = slice.getState();
    const {
      referralAddress,
      session: {
        type,
        address,
        isMainNet
      },
      forceBlock
    } = state;

    const error = sessionCheck(
      isMainNet && !forceBlock,
      address,
      type === ConnectionType.Metamask ? metamaskProvider : true,
    );
    if (error) {
      throw new Error(error);
    }

    const { token } = await authWithToken(type, address);

    const checksumAddress = ethers.utils.getAddress(address);
    const checksumReferralAddress = referralAddress ? ethers.utils.getAddress(referralAddress) : null;

    return {
      userData: await AuthApi.getData(checksumAddress, checksumReferralAddress, token),
      claimImage: await AuthApi.getImage(address, token),
    };
  },
);

export const claim = createAsyncThunk(
  'client/claim',
  async (_, slice) => {
    const state = slice.getState();
    const {
      referralAddress,
      session: {
        type,
        address,
        isMainNet
      },
      userData,
      forceBlock
    } = state;

    const error = sessionCheck(
      isMainNet && !forceBlock,
      address,
      type === ConnectionType.Metamask ? metamaskProvider : true,
    );
    if (error) {
      throw new Error(error);
    }

    if (!userData) {
      throw new Error('User is not authorized.');
    }
    const {
      amountV,
      r,
      s
    } = userData;

    if (!(amountV && r && s)) {
      return false;
    }

    await contractErrorParser(
      () => ContractApi.claim(
        type,
        address,
        amountV,
        r,
        s,
        referralAddress || '0x0000000000000000000000000000000000000000',
      ),
    );
    return true;
  },
);

export const stake = createAsyncThunk(
  'client/stake',
  async (amount, slice) => {
    const state = slice.getState();
    const {
      type,
      address
    } = state.session;

    if (+amount <= 0 || !+amount) {
      throw new Error('Invalid amount. Value should be greater than 0');
    }

    await contractErrorParser(() => ContractApi.stake(type, address, amount));
    await slice.dispatch(requestStakingData());
    slice.dispatch(requestStakingBalance());

    return amount;
  },
);

export const unstake = createAsyncThunk(
  'client/unstake',
  async (amount, slice) => {
    const state = slice.getState();
    const {
      type,
      address
    } = state.session;

    if (+amount <= 0 || !+amount) {
      throw new Error('Invalid amount. Value should be greater than 0');
    }

    const balance = await ContractApi.getBalance(address, ContractType.Staking);
    if (ethers.BigNumber.from(ethers.utils.parseEther(amount)
      .toString())
      .gt(ethers.BigNumber.from(balance))) {
      throw new Error('Not enough sTKNFY.');
    }

    await contractErrorParser(() => ContractApi.unstake(type, address, amount));
    await slice.dispatch(requestStakingData());
    slice.dispatch(requestStakingBalance());
  },
);

export const lpStake = createAsyncThunk(
  'client/lpStake',
  async (amount, slice) => {
    const state = slice.getState();
    const {
      type,
      address
    } = state.session;

    if (+amount <= 0 || !+amount) {
      throw new Error('Invalid amount. Value should be greater than 0');
    }

    await contractErrorParser(() => ContractApi.lpStake(type, address, amount));
    await slice.dispatch(requestStakingData());
    slice.dispatch(requestStakingBalance());

    return amount;
  },
);

export const lpUnstake = createAsyncThunk(
  'client/lpUnstake',
  async (amount, slice) => {
    const state = slice.getState();
    const {
      type,
      address
    } = state.session;

    if (+amount <= 0 || !+amount) {
      throw new Error('Invalid amount. Value should be greater than 0');
    }

    const balance = (await ContractApi.getLPUserInfo(address)).amount;
    if (ethers.BigNumber.from(ethers.utils.parseEther(amount)
      .toString())
      .gt(ethers.BigNumber.from(balance))) {
      throw new Error('Not enough $TKNFY-ETH LP tokens.');
    }

    await contractErrorParser(() => ContractApi.lpUnstake(type, address, amount));
    await slice.dispatch(requestStakingData());
    slice.dispatch(requestStakingBalance());
  },
);

export const lpClaim = createAsyncThunk(
  'client/lpClaim',
  async (_, slice) => {
    const state = slice.getState();
    const {
      type,
      address
    } = state.session;

    await contractErrorParser(() => ContractApi.lpClaim(type, address));
    await slice.dispatch(requestStakingData());
    slice.dispatch(requestStakingBalance());
  },
);

export const loadConnectionData = createAsyncThunk(
  'client/loadConnectionData',
  async () => {
    const session = {
      type: null,
      address: null,
      chainId: null,
    };

    if (connector.connected) {
      session.type = ConnectionType.WalletConnect;
      session.address = connector.accounts[0];
      session.chainId = +process.env.REACT_APP_MAIN_NET;
    } else {
      session.type = ConnectionType.Metamask;

      try {
        session.address = await signer.getAddress();
      } catch (e) {
        console.error(e.message);
      }

      session.chainId = parseInt(web3.provider.chainId, 16);
    }

    return session;
  },
);

export const addToken = createAsyncThunk(
  'client/addToken',
  async () => {
    try {
      await metamaskProvider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: process.env.REACT_APP_CONTRACT_ADDRESS,
            symbol: 'TKNFY',
            decimals: 18,
            image: 'https://tokenfy-production-claimer.s3.us-east-2.amazonaws.com/assets/logo.png',
          },
        },
      });
    } catch (err) {
      throw new Error(err.message);
    }
  },
);

export const addStakedToken = createAsyncThunk(
  'client/addToken',
  async () => {
    try {
      await metamaskProvider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: process.env.REACT_APP_STAKING_CONTRACT_ADDRESS,
            symbol: 'sTKNFY',
            decimals: 18,
            image: 'https://tokenfy-production-claimer.s3.us-east-2.amazonaws.com/assets/logo.png',
          },
        },
      });
    } catch (err) {
      throw new Error(err.message);
    }
  },
);

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setReferralAddress(state, action) {
      state.referralAddress = action.payload;
    },
    openModal(state, action) {
      state.currentModal = action.payload;
    },
    closeModal(state) {
      state.currentModal = Modals.Closed;
      state.stakingAmount = null;
    },
    closeErrorModal(state) {
      state.error = null;
    },
    handleAddressChange: (state, action) => {
      if (action.payload.type === state.session.type) {
        state.session = {
          type: state.session.type,
          address: ethers.utils.getAddress(action.payload.address),
          isMainNet: state.session.isMainNet,
        };
        state.currentModal = Modals.Closed;
        state.claimImage = null;
        state.userData = null;
        state.claimed = null;
        state.eligible = null;
      }
    },
    handleChainChange: (state, action) => {
      if (action.payload.type === state.session.type) {
        state.session = {
          type: state.session.type,
          address: ethers.utils.getAddress(state.session.address),
          isMainNet: action.payload.isMainNet
            ?? parseInt(action.payload.chainId, 16) === +process.env.REACT_APP_MAIN_NET,
        };
      }
    },
    handleDisconnect: (state, action) => {
      if (action.payload === state.session.type) {
        state.session = {
          type: null,
          address: null,
          isMainNet: true,
        };
        state.currentModal = Modals.Closed;
        state.claimImage = null;
        state.userData = null;
        state.claimed = null;
        state.eligible = null;
      }
    },
  },
  extraReducers: builder => {
    // Metamask
    builder.addCase(connectMetamask.pending, state => {
      state.isLoading = true;
      state.currentModal = Modals.Closed;
    });
    builder.addCase(connectMetamask.fulfilled, (state, action) => {
      state.session = {
        type: ConnectionType.Metamask,
        address: action.payload,
        isMainNet: web3.network.chainId === +process.env.REACT_APP_MAIN_NET,
      };
      state.isLoading = false;
    });
    builder.addCase(connectMetamask.rejected, (state, action) => {
      state.error = action.error.message || 'Error occurred while connecting via metamask';
      state.isLoading = false;
    });

    // WalletConnect
    builder.addCase(connectLedger.pending, state => {
      state.isLoading = true;
      state.currentModal = Modals.Closed;
    });
    builder.addCase(connectLedger.fulfilled, (state, action) => {
      state.session = {
        type: ConnectionType.WalletConnect,
        address: action.payload,
        isMainNet: true,
      };

      state.isLoading = false;
    });
    builder.addCase(connectLedger.rejected, (state, action) => {
      state.error = action.error.message || 'Error occurred while connecting via wallet connect';
      state.isLoading = false;
    });

    builder.addCase(requestContractData.fulfilled, (state, action) => {
      state.claimLive = action.payload.claimLive;
    });

    builder.addCase(loadConnectionData.fulfilled, (state, action) => {
      const address = action.payload.address;
      const isMainNet = action.payload.chainId === +process.env.REACT_APP_MAIN_NET;

      state.session = {
        type: action.payload.type,
        address,
        isMainNet,
      };

      if (!isMainNet) {
        state.forceBlock = true;
        state.error = `You are using the wrong network, please switch to
        ${process.env.REACT_APP_MAIN_NET_NAME} and reload the page.`;
      }
    });

    builder.addCase(checkClaim.fulfilled, (state, action) => {
      state.claimed = action.payload;
      // state.userData = null;
    });

    builder.addCase(loadUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadUserData.fulfilled, (state, action) => {
      const {
        amountV,
        r,
        s
      } = action.payload.userData;
      state.userData = action.payload.userData;
      state.claimImage = action.payload.claimImage;
      state.isLoading = false;
      state.eligible = !!(amountV && r && s);
    });
    builder.addCase(loadUserData.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    builder.addCase(claim.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(claim.fulfilled, (state, action) => {
      if (action.payload) {
        state.currentModal = Modals.Success;
        state.claimed = true;
      }

      state.isLoading = false;
    });
    builder.addCase(claim.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    builder.addCase(stake.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(stake.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stakingAmount = action.payload;
      state.curStakeProcess = StakingProcess.EarnStaking;
      state.currentModal = Modals.StakeProcess;
    });
    builder.addCase(stake.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    builder.addCase(unstake.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(unstake.fulfilled, (state, action) => {
      state.isLoading = false;
      state.curStakeProcess = StakingProcess.EarnUnstaking;
      state.currentModal = Modals.StakeProcess;
    });
    builder.addCase(unstake.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    builder.addCase(lpStake.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(lpStake.fulfilled, (state) => {
      state.isLoading = false;
      state.curStakeProcess = StakingProcess.LPStaking;
      state.currentModal = Modals.StakeProcess;
    });
    builder.addCase(lpStake.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    builder.addCase(lpUnstake.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(lpUnstake.fulfilled, (state) => {
      state.isLoading = false;
      state.curStakeProcess = StakingProcess.LPUnstaking;
      state.currentModal = Modals.StakeProcess;
    });
    builder.addCase(lpUnstake.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    builder.addCase(lpClaim.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(lpClaim.fulfilled, (state) => {
      state.isLoading = false;
      state.curStakeProcess = StakingProcess.LPMint;
      state.currentModal = Modals.StakeProcess;
    });
    builder.addCase(lpClaim.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });

    builder.addCase(requestStakingData.fulfilled, (state, action) => {
      const data = action.payload;
      const stakingRewards = +ethers.utils.formatEther(data.stakingRewards);
      const totalTokenfy = +ethers.utils.formatEther(data.totalTokenfy);
      const totalSupply = +ethers.utils.formatEther(data.totalSupply);

      state.staking.rewards = data.stakingRewards;
      state.staking.totalTokenfy = data.totalTokenfy;
      state.staking.totalSupply = data.totalSupply;
      state.staking.APR = stakingRewards && totalTokenfy ? +(100 * stakingRewards / totalTokenfy).toFixed(2) : null;
      state.staking.rate = totalTokenfy && totalSupply ? (totalTokenfy / totalSupply).toFixed(2) : null;
    });

    builder.addCase(requestStakingBalance.fulfilled, (state, action) => {
      const data = action.payload;
      state.userBalance.tokenfy = data.tokenfy;
      state.userBalance.sTokenfy = data.sTokenfy;
      state.userBalance.lpToken = data.lpToken;
      state.lpStakingRewards = data.lpStakingRewards;

      if (data.lpUserInfo) {
        state.lpUserInfo.amount = data.lpUserInfo.amount;
        state.lpUserInfo.rewardDebt = data.lpUserInfo.rewardDebt;
      }
    });

    builder.addCase(requestLPAPRData.fulfilled, (state, action) => {
      const {
        lpTokenBalance,
        lpTokenTotalSupply,
        lpRewardPerBlock,
        ethPrice,
        tknfyPrice,
        tknfyPoolAmount,
        ethPoolAmount,
      } = action.payload;

      // state.lpAPR = (
      //   365 * (reserves.token1Reserve * tknfyPrice + reserves.token2Reserve * ethPrice) / (100000000 * tknfyPrice)
      // ).toFixed(2);
      const rewardPerBlock = ethers.utils.formatEther(lpRewardPerBlock);

      state.lpAPR = (
        (100 * 365 * rewardPerBlock * 6500 * tknfyPrice) / (
          (tknfyPoolAmount * tknfyPrice + ethPoolAmount * ethPrice) * (lpTokenBalance / lpTokenTotalSupply)
        )
      ).toFixed(2);


      if (!state.lpAPR || isNaN(+state.lpAPR) || !isFinite(+state.lpAPR)) {
        state.lpAPR = 0;
      }

    });
    builder.addCase(requestLPAPRData.rejected, (state, action) => {
      console.error(action.error.message);
    });
  },
});

export default clientSlice.reducer;

export const {
  setReferralAddress,
  openModal,
  closeModal,
  closeErrorModal,
  handleAddressChange,
  handleChainChange,
  handleDisconnect,
} = clientSlice.actions;

export const selectCurrentModal = state => state.currentModal;
export const selectCurStakeProcess = state => state.curStakeProcess;
export const selectIsLoading = state => state.isLoading;
export const selectError = state => state.error;
export const selectAddress = state => state.session.address;
export const selectConnectionType = state => state.session.type;
export const selectReferralAddress = state => state.referralAddress;
export const selectClaimImage = state => state.claimImage;
export const selectClaimLive = state => state.claimLive;
export const selectClaimed = state => state.claimed;
export const selectUserData = state => state.userData;
export const selectEligible = state => state.eligible;
export const selectAPR = state => state.staking.APR;
export const selectLPAPR = state => state.lpAPR;
export const selectStakingRate = state => state.staking.rate;
export const selectTokenfyBalance = state => ethers.utils.formatEther(state.userBalance.tokenfy);
export const selectStakedTokenfyBalance = state => ethers.utils.formatEther(state.userBalance.sTokenfy);
export const selectLPTokenBalance = state => ethers.utils.formatEther(state.userBalance.lpToken);
export const selectLPStakingRewards = state => ethers.utils.formatEther(state.lpStakingRewards);
export const selectLPUserInfo = state => ({
  amount: ethers.utils.formatEther(state.lpUserInfo.amount),
  rewardDebt: ethers.utils.formatEther(state.lpUserInfo.rewardDebt),
});
export const selectStakingBalance = state => {
  const rate = state.staking.rate ?? 0;
  const sTokenfy = +selectStakedTokenfyBalance(state);
  return rate * sTokenfy;
};
export const selectStakingAmount = state => state.stakingAmount;
