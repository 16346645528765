import classNames from 'classnames';

// styles
import styles from './Button.module.css';

export default function Button({
  children,
  handleClick,
  isLink,
  className,
  href,
  ...props
}) {
  const Component = isLink ? 'a' : 'button';
  return (
    <Component
      className={classNames(styles.button, !href && Component === 'a' ? styles.disabled_link : null, className)}
      onClick={handleClick}
      href={href}
      {...props}
    >
      {children}
    </Component>
  );
}
