import { useDispatch, useSelector } from 'react-redux';
import {
  closeModal,
  selectAddress,
  selectClaimImage,
  selectCurrentModal
} from '../../store/client';
import { Share } from 'react-twitter-widgets';

// components
import StyledModal from '../common/StyledModal/StyledModal';
import Button from '../common/Button/Button';

// assets
import MEDAL from '../../assets/Easy User Interface.png';

// constants
import Modals from '../../shared/constants/modals';

// styles
import styles from './SuccessModal.module.css';

export default function SuccessModal() {
  const dispatch = useDispatch();
  const currentModal = useSelector(selectCurrentModal);
  const claimImage = useSelector(selectClaimImage);
  const address = useSelector(selectAddress);

  function close() {
    dispatch(closeModal());
  }

  return (
    <StyledModal
      title="Thank you"
      className={styles.modal}
      isOpen={currentModal === Modals.Success}
      handleClose={close}
    >
      <div className={styles.content}>
        <img src={MEDAL} alt="medal" />
        <p>
          Your NFT activity matters and drives overall community forward! 🔥
        </p>
        <p>
          Share your NFT achievements and future <span>$TKNFY</span> claim with friends!
        </p>

        <div className={styles.twitter_share_button}>
          <Button className={styles.infographic_button} isLink={true} href={`${claimImage}`}
                  target="_blank" download="image.png">
            Open my NFT infographic
          </Button>
          <Share
            url=" "
            options={{
              size: 'large',
              text: `Claim your free $TKNFY tokens! @tokenfy_ ${document.location.protocol}//${document.location.host}/?referralAddress=${address}`,
            }}
          />
        </div>
      </div>
    </StyledModal>
  );
}
